.card {
	width: 100%;
	height: 100%;
	padding: 10px;
	text-align: center;
	white-space: normal;

	.logo {
		width: 100%;
		height: 20%;
		display: flex;
		justify-content: center;

		svg {
			height: 100%;
			animation-delay: 1000ms;
			animation: grow-slide 800ms cubic-bezier(0.7, 0, 0.3, 1);


			@keyframes grow-slide {
			    0% {
			      transform: translateY(200%) scale(1.3) ;
			    }
			    100% {
			      transform: translateY(0) scale(1);
			    }
			}
		}
	}
	
	.text-container {
		height: 80%;
		display: flex;
		justify-content: center;

		.text {
			width: 100%;
			opacity: 0;
			animation-fill-mode: forwards;
			animation: fade-in 600ms cubic-bezier(0.7, 0, 0.3, 1) 800ms forwards;
			font-size: 1.6vw;

			@media screen and (max-width: 767px) {
				font-size: 3.5vw;
			}

			h3 {
				margin: 3% 0;
			}

			.gold {
				color: var(--color4);
			}

			.dual-grid {
				display: grid;
				grid-template-columns: 1fr 1fr;

				&.spread {
					margin-top: 8%;
					grid-row-gap: 10%;
				}
			}
		}
	}
}