.video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	* {
		outline: none;
	}

	:last-child {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}