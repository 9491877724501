.carousel-item-list {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	transform: translateY(-20px);
	padding: 20px 0;

	.item-container {
		margin: 20px 5%;
	}

	.list-more {
		margin-top: 20vw;
		width: 50vw;
		height: 50vw;
	}
}