.see-more {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	.outer-eye {
		width: 50%;
		height: 50%;
		background: white;
		border-radius: 75% 0;
		transform: rotate(45deg);
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		cursor: pointer;
		box-shadow: 5px 5px 50px -18px rgba(0,0,0,0.8);
		outline: none;

		.iris {
			position: relative;
			width: 70%;
			height: 70%;
			border-radius: 50%;
			background: var(--color4);
			overflow: hidden;

			.pupil {
				position: absolute;
				top: 30%;
				left: 30%;
				width: 40%;
				height: 40%;
				border-radius: 50%;
				background: black;
				display: flex;
				justify-content: flex-end;

				> * {
					width: 40%;
					height: 40%;
					border-radius: 50%;
					background: white;
				}
			}
		}

		.eye-lid {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 150%;
			height: 100%;
			transform: translate(-50%, -50%) rotate(-45deg);

			&:after {
				display: block;
				content: "";
				width: 100%;
				height: 0;
				border-radius: 0 0 50% 50%;
				background: var(--color2);
			}

			&.shut {
				&:after {
					animation: close-and-open 600ms ease-in-out;

					@keyframes close-and-open {
					    0% {
							height: 0;
					    }
					    50% {
					    	height: 100%;
					    }
					    100% {
							height: 0;
					    }
					}
				}
			}
		}
	}

	.more-line {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		margin-top: 0;
	}

	.options {
		position: absolute;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;

		> * {
			font-size: 110%;
		}
	}
}