.work-detail {
	text-align: center;
	animation: fade-in 600ms ease-in-out;

	h1 {
		font-weight: 700;
		
		@media screen and (max-width: 991px) {
			font-size: 1.8em;
		}
	}

	.video-container {
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 25px;
		height: 0;
		margin: 20px 0;
	}

	.info {
		display: flex;
		justify-content: space-between;

		@media screen and (max-width: 768px) {
			width: 70%;
			flex-direction: column;
			margin: 0 auto;
		}

		@media screen and (max-width: 575px) {
			width: 100%;
		}

		> :first-child {
			width: 60%;
			text-align: left;
			line-height: 30px;
			font-size: 110%;

			@media screen and (max-width: 991px) {
				font-size: 100%;
			}

			@media screen and (max-width: 768px) {
				width: 100%;
			}
		}

		.details {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			text-align: left;
			width: 30%;

			@media screen and (max-width: 991px) {
				width: 35%;
			}

			@media screen and (max-width: 768px) {
				width: 100%;
			}

			.detail-item {
				display: flex;
				flex-direction: column;
				margin: 15px 0;

				:first-child {
					color: var(--color4);
					font-size: 90%;
					margin-bottom: 4px;

					@media screen and (max-width: 991px) {
						font-size: 85%;
						margin-bottom: 2px;
					}
				}

				:last-child {
					@media screen and (max-width: 991px) {
						font-size: 95%;
					}
				}

				a {
					font-size: unset;
				}
			}
		}
	}
}