.home {
	height: 100vh;
	white-space: nowrap;
	overflow: hidden;
	animation: fade-in 600ms ease-in-out;

	@media screen and (max-width: 767px) {
		height: unset;
	}

	.home-content {
		height: 100vh;
		width: 100vw;
		position: fixed;

		@media screen and (max-width: 767px) {
			position: static;
			height: unset;

			.home-gif {
				width: 80vw;
				height: 45vw;
				margin: 90px 11% 0 9%;
				z-index: -1;

				img {
					width: 100%;
				}
			}
		}	
	}
}