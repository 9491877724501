.carousel-item {
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	position: relative;
	background: var(--color1);
	transition: all 300ms cubic-bezier(0.7, 0, 0.3, 1);
	overflow: hidden;

	&:after {
		padding-bottom: 56.25%;
		content: "";
		display: block;

		@media screen and (min-width: 768px) and (max-width: 1075px) {
			padding-bottom: 64%;
		}
	}

	&.show {
		opacity: 1;
		transform: translateY(-50%) scale(1.08);
		box-shadow: 5px 5px 140px -18px rgba(0,0,0,0.8);

		@media screen and (max-width: 767px) {
			box-shadow: 5px 5px 50px -18px rgba(0,0,0,0.8);
			transform: none;
		}
	}

	&.hide {
		opacity: 0.3;
		box-shadow: none;
	}

	.background-canvas {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.inner-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 300ms cubic-bezier(0.7, 0, 0.3, 1);

		&.show {
			opacity: 1;
		}

		&.hide {
			opacity: 0;
		}
	}
}