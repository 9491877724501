@mixin scrimGradient($direction, $startColor) {
  
  $scrimCoordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );
  
  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();
  
  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }
  
  background: linear-gradient(unquote($direction), $stops);
  
}

.work-item {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	position: relative;
	padding: 20px;
	cursor: pointer;
	background: var(--color2);
	background-size: cover;
	background-position: center;
	overflow: hidden;
	transition: all 300ms ease-in-out;

	@media screen and (min-width: 576px) {
		&:hover {

			.image {
				&.show {
					opacity: 0.1
				}
			}

			.details {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}

	.floater-load {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.image {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		transition: all 300ms ease-in-out;

		&.show {
			opacity: 1;
		}
	}

	.gradient {
		position: absolute;
		top: 40%;
		left: 0;
		right: 0;
		bottom: 0;
		@include scrimGradient('to top', rgba(39, 35, 67, 0.3));
	}

	.details {
		display: flex;
		flex-direction: column;
		color: white;
		font-size: 1em;
		transition: all 300ms ease-in-out;
		transform: translateY(20px);
		opacity: 0;

		@media screen and (max-width: 575px) {
			opacity: 1;
			transform: none;
			z-index: 0;
		}

		h3 {
			margin: 5px 0;
		}
	}
}