.footer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 10%;
	border-left: dashed 1px var(--color4);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 3% 0;
	animation: footer-slide-down 1500ms cubic-bezier(0.7, 0, 0.3, 1);


	@media screen and (max-width: 991px) {
		position: absolute;
		border-left: unset;
		padding: unset;
		width: unset;
		bottom: unset;
		top: 5%;
		right: 5%;
		justify-content: flex-start;
		align-items: flex-end;

		&.is-home {
			position: fixed;
		}
	}

	@media screen and (max-width: 767px) {
		&.is-home {
			position: absolute;
		}
	}

	@keyframes footer-slide-down {
		0% {
			transform: translateY(-100%);
		}
		100% {
			transform: translateY(0);
		}
	}
}