.carousel {
	position: relative;
	height: 100%;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;

		.port {
			width: 35%;
			position: fixed;
			left: 3%;
			top: 50%;
			transform: translate(0, -50%);
			z-index: -1;

			@media screen and (max-width: 767px) {
				top: 60px;
				width: 97%;
				transform: unset;
			}
		}

	.slider-wrapper {
		position: absolute;
		top: 50vh;
		height: 60vh;
		margin-left: 75vw;

		.container {
			display: inline-block;
			width: 40vw;
			height: 100%;
			margin: 0 5vw;
		}
	}

	.message {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		align-items: center;

		.oval {
			position: relative;
			width: 40px;
			height: 60px;
			border-radius: 25px;
			border: solid 2px white;
			margin-bottom: 22px;
			animation: slide-up 1400ms cubic-bezier(0.7, 0, 0.3, 1);

			.line {
				background: white;
				width: 2px;
				height: 20px;
				position: absolute;
				top: 15px;
				left: 50%;
				transform: translateX(-50%);

				:first-child {
					bottom: 0;
					left: 50%;
					position: absolute;
					width: 12px;
					height: 12px;
					border-bottom: solid 2px white;
					border-left: solid 2px white;
					transform: translateX(-50%) rotate(-45deg);
				}
			}

			@keyframes slide-up {
			    0% {
			      opacity: 0;
			      transform: translateY(15px);
			    }
			    100% {
			      opacity: 1;
			      transform: translateY(0);
			    }
		    }
		}

		.scroll {
			letter-spacing: 2px;
			font-size: 110%;
			animation: slide-down 1400ms cubic-bezier(0.7, 0, 0.3, 1);

			@keyframes slide-down {
			    0% {
			      opacity: 0;
			      transform: translateY(-15px);
			    }
			    100% {
			      opacity: 1;
			      transform: translateY(0);
			    }
		    }
		}
	}

	.more {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 20vw;
		height: 20vw;
	}

	.social-panel {
		position: fixed;
		top: 50%;
		right: 0;
		width: 10vw;
		transform: translateY(-50%);

		@media screen and (max-width: 991px) {
			right: 2%;
		}
	}

	.indicators {
		position: fixed;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		opacity: 0;
		transition: all 300ms ease-in-out;

		&.show {
			opacity: 1;
		}

		> * {
			letter-spacing: 2px;
		}
	}
}