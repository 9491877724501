.header {
  position: fixed;
  top: 5%;
  left: 5%;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    position: absolute;

    &.is-home {
      position: fixed;
    }
  }
  
  @media screen and (max-width: 767px) {
    &.is-home {
      position: absolute;
    }
  }

  .name {
  	display: flex;
  	flex-direction: column;
    animation: fade-in 1500ms cubic-bezier(0.7, 0, 0.3, 1);
    cursor: pointer;
    font-family: unset;

    &:hover {
      span {
        color: var(--color4);
      }
    }
	
  	span {
      font-family: 'Inconsolata', monospace, sans-serif;
  		display: block;
  		font-size: 18px;
      line-height: 29px;
  		letter-spacing: 6px;
  		font-weight: 500;
  		color: white;
      transition: all 300ms ease-in-out;

      @media screen and (max-width: 1400px) {
        font-size: 16px;
        line-height: 25px;
      }

      @media screen and (max-width: 991px) {
        font-size: 14px;
        line-height: 20px;
      }
  	}
  }

  .action-button {
    position: fixed;
    bottom: 5%;
    left: 5%;
  	width: 60px;
  	height: 60px;
  	background: var(--color4);
  	border-radius: 50%;
  	display: flex;
  	justify-content: center;
  	align-items: center;
  	cursor: pointer;
  	transition: all 300ms ease-in-out;
    animation: fade-in 1500ms cubic-bezier(0.7, 0, 0.3, 1);

    @media screen and (max-width: 991px) {
      width: 52px;
      height: 52px;
    }

  	&:hover {
  		background: #fff;

  		svg {
  			fill: var(--color4);
  		}
  	}

  	svg {
  		width: 50%;
  		fill: #fff;
  		transition: all 300ms ease-in-out;
  	}
  }

  @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
}