.error-boundary {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	img {
		max-width: 300px;
		height: auto;
		margin: 0 0 20px 0;
	}
}