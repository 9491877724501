.loader {
	top: 50%;
	left: 50%;
	border: 4px solid #fff;
	border-top: 4px solid var(--color4);
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 1000ms linear infinite;

	@media screen and (max-width: 991px) {
		width: 52px;
		height: 52px;
    }

	&.fixed {
		position: fixed;
	}

	&.absolute {
		position: absolute;
	}

	@keyframes spin {
		0% { transform: translate(-50%, -50%) rotate(0deg); }
		100% { transform: translate(-50%, -50%) rotate(360deg); }
	}
}

