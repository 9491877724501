.social-buttons {
	display: flex;
	align-items: center;
	justify-content: center;

	&.vertical {
		flex-direction: column;
	}

	.social-button {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 10px;
		transition: all 300ms ease-in-out;
		opacity: 0.3;
		animation: fade-in-pause-30 2000ms ease-in-out;

		@keyframes fade-in-pause-30 {
			0% {
				opacity: 0;
			}
			70% {
				opacity: 0;
			}
			100% {
				opacity: 0.3;
			}
		}

		&:hover {
			opacity: 1;
		}

		svg {
			width: 50%;
			height: 50%;
			fill: var(--color1);
		}
	}
}