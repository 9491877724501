.about {
	width: 65%;
	margin: 2% auto;
	animation: fade-in 600ms ease-in-out;
	position: relative;

	@media screen and (max-width: 991px) {
		width: 70%;
		margin: 150px auto 0 auto;
	}

	@media screen and (max-width: 767px) {
		width: 88%;
	}

	.content {
		.details {
			width: 50%;
			line-height: 30px;
			font-size: 110%;

			@media screen and (max-width: 767px) {
				width: 100%;
			}
		}

		.intro {
			font-size: 300%;
			line-height: 52px;
			margin-top: 0;
			font-weight: 700;

			@media screen and (max-width: 991px) {
				line-height: 48px;
				font-size: 250%;
			}

			@media screen and (max-width: 575px) {
				line-height: 34px;
				font-size: 170%;
			}
		}

		.interest {
			font-size: 160%;
			margin-bottom: 40px;
			font-weight: 600;

			@media screen and (max-width: 991px) {
				font-size: 140%;
			}

			@media screen and (max-width: 575px) {
				font-size: 140%;
			}

			.gold {
				color: var(--color4);
			}
		}

		.contact {
			display: flex;
			flex-direction: column;
			font-size: 110%;

			.row {
				display: flex;
				margin-bottom: 8px;

				> * {
					display: block;
				}

				:first-child {
					margin-right: 16px;
					color: var(--color4);
				}

				a {
					font-size: unset;
				}
			}
		}

		.gif {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 20px 0;

			&:after {
				content: "";
				display: block;
				padding-bottom: 56.25%;
			}
			
			.monitor-gif {
				width: 80%;
				height: 45%;
				position: static;
				transform: none;
			}
		}
	}	

	.monitor-gif {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 40%;
	}
}