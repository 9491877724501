.work {
	width: 65%;
	margin: 5% auto;

	@media screen and (max-width: 991px) {
		width: 88%;
		margin: 150px auto 0 auto;
	}

	.work-header {
		margin: 22px auto;
		display: flex;
		justify-content: center;
		text-align: center;
	}

	.work-collection {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(10, 1fr);
		grid-template-areas: 
			"joe joe"
			"joe joe"
			"breathe breathe"
			"breathe breathe"
			"spent spent"
			"spent spent"
			"too too"
			"too too"
			"tele tele"
			"tele tele"
			"holder1 wechat"
			"holder1 wechat";
		grid-column-gap: 1em;
		grid-row-gap: 1em;
		transition: all 300ms ease-out;

		@media screen and (min-width: 576px) {
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: repeat(6, 1fr);
			grid-template-areas: 
				"joe joe breathe breathe"
				"joe joe breathe breathe"
				"spent spent too too"
				"spent spent too too"
				"tele tele holder1 wechat"
				"tele tele holder1 wechat";
			grid-column-gap: 0.8em;
			grid-row-gap: 0.8em;

			.holder2 {
				grid-area: holder2;
			}
		}

		&::before {
		  content: '';
		  width: 0;
		  padding-bottom: 100%;
		  grid-row: 1 / 1;
		  grid-column: 1 / 1;
		}

		> * {
			opacity: 0;
			animation: fade-in 1000ms ease-out;
			animation-fill-mode: forwards;
		}

		.holder1 {
			grid-area: holder1;
		}
	}
}