:root {
  --color1: rgba(63, 59, 89, 0.8);
  --color2: #272343;
  --color3: rgb(74, 24, 93);
  --color4: rgb(162, 125, 87);
  --color5: rgb(235, 65, 46);
}


* {
  box-sizing: border-box;
}

html {
  background: var(--color2);
}

@font-face {
  font-family: sofia-pro, sans-serif;
  font-display: swap;
}

body {
  color: white;
  overflow-x: hidden;
  letter-spacing: 1.5px;
  font-family: sofia-pro, sans-serif;
}

h1, h2, h3 {
  letter-spacing: 4px;
  font-weight: 100;
  color: var(--blue);
}

h1 {
  font-size: 3em;
}

a {
  outline: none;
  text-decoration: none;
  color: white;
  font-size: 1.5em;
  transition: all 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: var(--color4);
  }

  @media screen and (max-width: 1180px) {
    font-size: 1.2em;
  }
}

.App {
  @media screen and (max-width: 991px) {
    .bottom-panel {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
    }
  }

  .side-panel {
    content: "";
    background: var(--color1);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40%;
    opacity: 0.6;
    z-index: -1;
    animation-fill-mode: forwards;
    animation: slide-left 1500ms cubic-bezier(0.7, 0, 0.3, 1);

    @media screen and (max-width: 767px) {
      opacity: 0;
      animation: none;
    }
  }

  @keyframes slide-left {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 0.6;
      transform: translateX(0);
    }
  }

  .nav-box {
    width: 10%;
    position: fixed;
    right: 0;
    display: flex;
    justify-content: center;
    height: 20vh;
    padding: 3% 0;


    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
      align-items: flex-end;
    }
  }
}
