.enter {
	opacity: 0;
	width: 100%;
	animation-fill-mode: forwards;

	&.fade-up {
		animation: fade-up 600ms ease-out forwards;

		@keyframes fade-up {
		    0% {
		      opacity: 0;
		      transform: translateY(30px);
		    }
		    100% {
		      opacity: 1;
		      transform: translateY(0);
		    }
	    }
	}
}